import { SocketoneService, SocketthreeService, SockettwoService } from './../../service/socketconnection.service';
import { Router } from '@angular/router';

import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './../../service/auth/auth.service';
import {LoadingBarService } from '@ngx-loading-bar/core';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  public notificationdata = [];
  public imageurl;
  public username = 'Username';
  public projectNotifyData = [];
  public isNotification = undefined;
  isHeader;

  constructor(
    private loadingbar: LoadingBarService,
    private socket: SocketoneService,
    private socket2: SockettwoService,
    private socket3: SocketthreeService,
    private cookie: CookieService,
    private authservice: AuthService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.authservice.showHeader.subscribe(data => {
      if(this.cookie.check('register') ) {
        this.isHeader = false
      } else {
        this.isHeader = data
      }
      this.cd.detectChanges()

    })
    


    this.authservice.profileData.subscribe((data) => {
      console.log(data);
      this.imageurl = data.avatar;
      this.username = data.username;
      if(data.block == true) {
        console.error("df")
        $("#blockModal").modal('show')
      } else {
        console.error("dfsdf")
        $("#blockModal").modal('hide')
      }
      this.cd.detectChanges()
      // this.notifyCounter()
    });

    this.socket.on('headerMessage', (data) => {
      console.log('notification');
      console.log(data);
      this.notificationdata.push(data);
      this.notificationdata;
      let tempArr = [];
      if (data.message.owner == 'engineer') {
        tempArr.push(data);
      }

      this.squash(tempArr);
    });
    this.socket3.on('adminNotify', data => {
      console.log(data);
      if(data.content == "Admin has blocked you") {
        $("#blockModal").modal('show')
      } else {
        $("#blockModal").modal('hide')

      }
      this.isNotification = true;
      this.projectNotifyData.unshift(data)
    })

    this.socket.emit('getIntialNotify', {
      token: this.cookie.get('token'),
      owner: 'engineer',
    });

    this.socket.on('initialNotify', (data) => {
      console.log(data);
      this.projectNotifyData = data.notifications.reverse();
      data.notifications.forEach((element) => {
        if (element.status == 'unread') {
          this.isNotification = true;
          return true;
        }
      });
    });
    this.socket.on('initialMessageNotify', (data) => {
      console.log(data);
      this.notificationdata = data.allUnreadMessages;
      this.notificationdata.forEach((element) => {
        if (element.message.status == 'unread') {
          this.isNotification = true;
          return true;
        }
      });
    });

    this.socket.on('singleNotify', (data) => {
      this.isNotification = true;
      console.log(data);
      this.projectNotifyData.unshift(data);
    });
    this.socket2.on('singleNotify', (data) => {
      this.isNotification = true;
      console.log(data);
      this.projectNotifyData.unshift(data);
    });

    this.socket.on('socket', (data) => console.log(data.socket));
  }

  ngOnDestroy() {
    // this.authservice.headerColor.unsubscribe();
    // this.color = false;
    this.notificationdata = [];
    this.projectNotifyData = []
  }
  ngAfterViewInit() {

  }

  logout() {
    this.loadingbar.start(10);

    this.authservice.logout().subscribe(
      () => {
        this.loadingbar.stop();
        this.cookie.deleteAll('/');
        if (!this.cookie.check('token')) {
          // this.authservice.avatarData.next(false)
          // this.authservice.header.next(false);
          this.authservice.showHeader.next(false)
          this.authservice.profileData.next({})

          this.router.navigate(['/onboard/login']);
        }
      },
      (err) => this.loadingbar.stop()
    );
  }

  goto(id, index) {
    this.socket.emit('readNotification', {
      notificationID: id,
      owner: 'client',
    });
    console.log(id);

    this.socket.on('readNotificationResponse', (response) => {
      console.log(response);
      if (response == true) {
        this.projectNotifyData[index].status = 'read';
        const yoyo = this.projectNotifyData.some((proj) => proj.status.includes('unread'));
        this.isNotification = yoyo;
        this.notificationdata.splice(index, 1);
      }
      console.log(this.isNotification);
    });
  }

  squash(arr) {
    this.notificationdata = [];
    for (var i = 0; i < arr.length; i++) {
      if (this.notificationdata.indexOf(arr[i]) == -1) {
        this.notificationdata.push(arr[i]);
      }
    }
    this.notificationdata.unshift();
    console.log(this.notificationdata);

    this.notificationdata.forEach((element) => {
      if (element.message.status == 'unread') {
        this.isNotification = true;
        return true;
      }
    });
  }

  test(e) {
    console.log(e.target);
    e.preventDefault();
    e.stopPropagation();
    $(e.target).tab('show');
  }

  clearNotification() {
    this.authservice.notificationClear().subscribe((data: any) => {
      console.log(data);
      
      this.notificationdata = [];
      this.projectNotifyData = data?.notification.notifications;
      this.isNotification = false;
    });
  }

  notifyCounter() {
    // console.error("notify");
    
    let count = 0;
    for (let i = 0; i < this.projectNotifyData.length; ++i) {
      if (this.projectNotifyData[i].status == 'unread') count++;
    }
    if(count + this.notificationdata?.length == 0) {
      this.isNotification = false;
    }

    return count + this.notificationdata?.length;
  }

  removeBtn() {
    console.log("sdfsdf");
    
    $('#navbarSupportedContent').collapse("hide");
  }
}
