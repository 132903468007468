import { SocketoneService, SocketthreeService, SockettwoService } from './service/socketconnection.service';

import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './service/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public imageurl;
  public isHeader
  constructor(
    public sanitizer: DomSanitizer,
    private socket: SocketoneService,
    private cookie: CookieService,
    private socket2: SockettwoService,
    private socket3: SocketthreeService,
    public authservice: AuthService
  ) {}
  ngOnInit() {

    if(this.authservice.isLoggedIn()) {
      this.authservice.showHeader.next(true)
    } else {
      this.authservice.showHeader.next(false)

    }


    if (this.cookie.check('token')) {
      this.getProfileData();
      console.log(`jwt ${this.cookie.get('token')}`);
      this.socket.on('isReconnected', (data) => {
        console.log('reconnect running');
        if (data.status == true) {
          console.log('reconnect status true');

          this.socket.emit('tryReconnect', {
            token: this.cookie.get('token'),
            owner: 'client',
          });
        }
      });
      this.socket2.on('isReconnected', (data) => {
        console.log('reconnect running');
        if (data.status == true) {
          console.log('reconnect status true');

          this.socket2.emit('tryReconnect', {
            token: this.cookie.get('token'),
            owner: 'client',
          });
        }
      });

      this.socket2.on('isReconnected', data => {
        console.log(data);
        this.socket2.emit('tryReconnect', {'token': this.cookie.get('token'),'owner': 'client'})    
      })

      this.socket.emit('joinRoom', {
        token: this.cookie.get('token'),
        owner: 'client',
      });
    } else {
      console.log('no token');
    }
  }

  getProfileData() {
    this.authservice.viewprofile().subscribe((data: any) => {
      console.log(data);
      // this.authservice.checkBlock.next(data.block);
      // this.username = data.username;
      if (data.avatar) {
        let TYPED_ARRAY = new Uint8Array(data.avatar.data);
        const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');

        let base64String = btoa(STRING_CHAR);
        this.imageurl = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + base64String);
        // console.log(this.imageurl);
      }
      this.authservice.profileData.next({
        username: data.username,
        avatar: this.imageurl,
        block:data.block
      });
    });
  }
}
